@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bitter-font: "Bitter", serif;
  --inter-font: "Inter", sans-serif;
}

@layer utilities {
  .optionSelected {
    color: #f2617a;
    border-color: #f2617a;
  }
  .navbar-selected {
    background-color: #f2617a;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.ace-tm,
.ace_gutter {
  background-color: transparent !important;
}

* {
  color: white;
}

textarea {
  outline: none;
  border-radius: 4px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  background-color: #003d50;
}
